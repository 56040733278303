<template>
  <section class="gen-section-padding-3 gen-single-video">
      <div class="container">
         <div class="row no-gutters">
            <div class="col-lg-12">
               <div class="row">
                  <div class="col-lg-12">
                    <div class="pm-inner">
                      <div class="gen-more-like">
                          <h5 class="gen-more--search"  v-for="publish in cat.slice(0,1)" :key="publish.id"> التصنيف : {{ publish.name_ar }}</h5>
                            <div class="row post-loadmore-wrapper">
                                <div class="col-xl-3 col-lg-4 col-md-6" v-for="publish in cat" :key="publish.id">
                                    <div class="gen-carousel-movies-style-1 movie-grid style-1">
                                        <div class="gen-movie-contain" @click="getFeed(publish)">
                                            <div class="gen-movie-img" >
                                                <a  @click="getFeed(publish)" >
                                                    <img v-lazy="publish.carsoul_img" alt="single-video-image" style="max-height: 280px;">
                                                    <div class="gen-movie-action">
                                                       <SVGPlay/>
                                                    </div>
                                                </a>
                                                <div class="gen-info-contain">
                                                    <div class="gen-movie-info">
                                                        <h3><a @click="getFeed(publish)">{{publish.title_ar}}</a></h3>
                                                    </div>
                                                    <div class="gen-movie-meta-holder">
                                                        <ul>
                                                            <li>{{publish.year}}</li>
                                                            <li><a @click="getFeed(publish)"><span>{{publish.name_ar}}</span></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { useCookie } from 'vue-cookie-next'
import { ref } from '@vue/reactivity';
import { useRouter, useRoute } from "vue-router"
import { onMounted } from '@vue/runtime-core';
import SVGPlay from "../SVG/SVGPlay.vue";
export default {
   name: 'AppCategorys',
   components: { 
      SVGPlay
    },
   props: {
       catid: String,
    },
     setup(props) {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const cat = ref([]);
        const GetCategories = async () => {
            await HTTP.get('GetContentByCategory.php?LIMIT=50&OFFSET=0&cat_id=' + props.catid).then(res => {
                cat.value = res.data.Content;
            })
        }
        onMounted(() => {
            GetCategories();
        });

        const getFeed = (publish) => {
            cookie.setCookie('cat_id', publish.cat_id);
        if(cookie.isCookieAvailable("msisdn") && cookie.isCookieAvailable("status") && cookie.isCookieAvailable("remming_minutes") > 0 ) {
          router.push({ name: "Contents", params: { content_id: publish.id } });
        } else {
          router.push({ name: "Subscribes" });
        }
      };
        return { cat, getFeed };
    },
}
</script>

<style>

</style>